<template>
  <!--編輯過後加上.edited-->
  <div class="data-row" :class="{ edited: isEdited, active: edit }">
    <div class="item buyer">{{ order.buyer_name }}</div>
    <div class="item qty">{{ itemsCount }}</div>
    <div class="item price">${{ orderPrice }}</div>
    <div class="item edit">
      <div class="edit-btn" :class="{ active: edit }" @click="clickEdit"></div>
    </div>
    <div class="edit-row-wrap">
      <div
        class="edit-row"
        v-for="(item, index) in order.group_buying_order_details"
        :key="`folow-order-${order.id}-${item.id}`"
      >
        <div class="product">
          {{ item.name }}
          <small style="color:#E30000;">
            {{ item.spec_name }}
          </small>
        </div>
        <QtyInput
          :productIndex="index"
          :quantity="item.actual_quantity"
          @quantity-change="quantityUpdate"
        ></QtyInput>
        <div class="price edited">${{ item.subtotal }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import QtyInput from '@/components/GroupProductQtyInput';
import { cloneDeep } from 'lodash';
import { groupReduceQuantity } from '@/api/group-buying';

export default {
  components: {
    QtyInput
  },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      order: {
        buyer_name: null,
        items_count: 0,
        price: 0,
        origin_items_count: 0,
        group_buying_order_details: []
      },
      edit: false
    };
  },
  created() {
    this.setDefault();
  },
  computed: {
    isEdited() {
      return this.itemsCount !== this.order.origin_items_count;
    },
    itemsCount() {
      let count = 0;
      this.orderDetails.forEach(ele => {
        count += ele.actual_quantity;
      });

      return count;
    },
    orderPrice() {
      let subtotal = 0;
      this.orderDetails.forEach(ele => {
        subtotal += ele.subtotal;
      });

      return subtotal;
    },
    orderDetails: {
      get() {
        return this.order.group_buying_order_details;
      },
      set(val) {
        this.order.group_buying_order_details = val;
      }
    },
    orderDetailsToUpdate() {
      return this.orderDetails.filter(
        ele => ele.actual_quantity !== ele.origin_actual_quantity
      );
    }
  },
  watch: {
    edit(newVal, oldVal) {
      if (oldVal === true && this.orderDetailsToUpdate.length > 0) {
        groupReduceQuantity({
          member_group_buying_order: this.order.id,
          group_buying_order_details: this.orderDetailsToUpdate
        })
          .then(data => {
            this.$emit('order-change');
          })
          .catch(() => {
            this.setDefault();
          });
      }
    },
    value: {
      deep: true,
      handler(val) {
        this.order = cloneDeep(val);
      }
    }
  },
  methods: {
    clickEdit() {
      this.edit = !this.edit;

      if (this.edit === true) {
        this.$emit('edit-open');
      } else {
        this.$emit('edit-close');
      }
    },
    quantityUpdate(prod) {
      let item = this.order.group_buying_order_details[prod.index];
      let quantity = prod.quantity;

      item.actual_quantity = quantity;
      item.subtotal = item.actual_quantity * item.sale_price;
    },
    setDefault() {
      this.order = cloneDeep(this.value);
    }
  }
};
</script>
