<template>
  <div class="main-content">
    <div class="data-container list">
      <div class="data-head">
        <div class="data-row">
          <div class="item product">品項</div>
          <div class="item qty">總數量</div>
          <div class="item price">總金額</div>
        </div>
      </div>
      <div class="data-body">
        <div
          class="data-row"
          v-for="(product, index) in group.group_buying_products"
          :key="`product-${index}`"
        >
          <div class="item product">
            {{ product.name }}
            <small>({{ product.spec_name }})</small>
          </div>
          <div class="item qty">
            {{ product.actual_quantity }}
            <span>{{ product.unit }}</span>
          </div>
          <div class="item price">${{ product.subtotal }}</div>
        </div>
      </div>
    </div>
    <div class="content-block">
      <div class="container">
        <div class="data-container edit-list">
          <div class="data-head">
            <div class="data-row">
              <div class="item buyer">購買者</div>
              <div class="item qty">數量</div>
              <div class="item price">銷售金額</div>
              <div class="item edit">編輯</div>
            </div>
          </div>
          <div class="data-body">
            <FollowOrder
              v-for="(order, index) in group.member_group_buying_orders"
              :key="`follow-order-${order.id}`"
              :orderIndex="index"
              :value="order"
              @edit-open="editCount += 1"
              @edit-close="editCount -= 1"
              @order-change="setData"
            ></FollowOrder>
          </div>
        </div>
      </div>
    </div>
    <div class="content-block" v-if="editCount > 0">
      <div class="container">
        <h4 class="text-danger text-center m-5">
          調整完畢請點擊
          <i class="edit-btn active"></i>
          存檔
        </h4>
      </div>
    </div>
    <div class="bottom-btn-block">
      <router-link
        class="btn-cta"
        :to="{
          name: 'CreatedGroupNotification',
          params: {
            group_id: groupId,
            type: 'reduced'
          }
        }"
      >
        <!-- 刪單通知 -->
        增刪單通知
      </router-link>
    </div>
  </div>
</template>

<script>
import { getGroupReducedList } from '@/api/group-buying';
import FollowOrder from '@/components/GroupManage/FollowOrder';

export default {
  components: {
    FollowOrder
  },
  data() {
    return {
      group: {
        member_group_buying_orders: []
      },
      editCount: 0 //編輯中
    };
  },
  //TODO: 已完成步驟二不能回來
  mounted() {},
  computed: {
    groupId() {
      return this.$route.params.group_id;
    }
  },
  watch: {
    groupId: {
      immediate: true,
      handler(groupId) {
        this.setData();
      }
    }
  },
  methods: {
    setData() {
      getGroupReducedList({
        group_buying_order_id: this.groupId
      }).then(data => {
        this.group = data;
      });
    }
  }
};
</script>
